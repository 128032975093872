// WaGen___HASHKEY__d8ade84e_2021-06-10 16:28:28 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
标准CRUD方法：添加记录
*/
export function scsdDlsbjhmx_add(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjhmx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：yhbh : Stringjhyf : Integer
*/
export function scsdDlsbjhmx_update(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjhmx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：yhbh : Stringjhyf : Integer
*/
export function scsdDlsbjhmx_remove(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjhmx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function scsdDlsbjhmx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjhmx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function scsdDlsbjhmx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjhmx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function scsdDlsbjhmx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjhmx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：yhbh : Stringjhyf : Integer
*/
export function scsdDlsbjhmx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjhmx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function scsdDlsbjhmx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function scsdDlsbjhmx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //ScsdDlsbjhmx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function scsdDlsbjhmx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjhmx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function scsdDlsbjhmx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjhmx
		}
	})
}

/* ---
未提供注释
*/
export function scsdDlsbjhmx_updateDlsbjhmx(jhbh, pagination, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/updateDlsbjhmx',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jhbh : jhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
多记录插入
*/
export function scsdDlsbjhmx_insertList(list, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ScsdDlsbjhmx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：yhbh : Stringjhyf : Integer
*/
export function scsdDlsbjhmx_updateList(list, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ScsdDlsbjhmx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：yhbh : Stringjhyf : Integer
*/
export function scsdDlsbjhmx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjhmx/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ScsdDlsbjhmx>
		}
	})
}

