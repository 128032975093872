// WaGen___HASHKEY__f87e70df_2021-06-09 15:43:06 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
标准CRUD方法：添加记录
*/
export function scsdDlsbjh_add(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjh
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：jhbh : String
*/
export function scsdDlsbjh_update(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjh
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：jhbh : String
*/
export function scsdDlsbjh_remove(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjh
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function scsdDlsbjh_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjh
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function scsdDlsbjh_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjh
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function scsdDlsbjh_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjh
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：jhbh : String
*/
export function scsdDlsbjh_fetch(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjh
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function scsdDlsbjh_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function scsdDlsbjh_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //ScsdDlsbjh
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function scsdDlsbjh_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjh
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function scsdDlsbjh_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ScsdDlsbjh
		}
	})
}

/* ---
多记录插入
*/
export function scsdDlsbjh_insertList(list, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ScsdDlsbjh>
		}
	})
}

/* ---
多记录更新,需要的主键字段：jhbh : String
*/
export function scsdDlsbjh_updateList(list, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ScsdDlsbjh>
		}
	})
}

/* ---
多记录删除,需要的主键字段：jhbh : String
*/
export function scsdDlsbjh_deleteList(list, meta) {
	return fetch({
		url : 'psdmsschjy/scsdschema/ScsdDlsbjh/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ScsdDlsbjh>
		}
	})
}

